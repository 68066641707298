






















// This component fetches a list of stories and displays them as a list of StoryComponent's
import { Component, Prop, Vue } from 'vue-property-decorator';
import { NFTUser } from '../NFTUser'
import * as utils from '../utils';
import * as utils_time from '../utils_time';
import * as utils_auth from '../utils_auth';
import * as utils_tracker from '../utils_tracker';
import { globalUC } from '../UserCache'

const DEBUGGING: boolean = false

@Component({
  name: 'FAQComponent',
  components: {}
})
export default class FAQComponent extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading; }
  public get user(): NFTUser { return this.$store.getters.userObject }
  public get userId(): string { return this.$store.getters.userId }
  public get isAuthenticated(): NFTUser { return this.$store.getters.isAuthenticated }
  public get siteName(): string { return 'LiveToken' }
  public get siteDomain(): string { return utils.getCurrentHostname() }

  public onOK() {
    this.$emit('close', true)
  }

  // private functions not available directly to HTML template
  private async mounted() {
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)

    utils_tracker.page(this.$options.name)

    // if (this.$store.state.devmode) console.dir(this.u)
  }

  private async beforeDestroy() { if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`) }

}
